// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ai-support-js": () => import("./../src/pages/ai-support.js" /* webpackChunkName: "component---src-pages-ai-support-js" */),
  "component---src-pages-all-articles-js": () => import("./../src/pages/all-articles.js" /* webpackChunkName: "component---src-pages-all-articles-js" */),
  "component---src-pages-approval-tests-js": () => import("./../src/pages/approval-tests.js" /* webpackChunkName: "component---src-pages-approval-tests-js" */),
  "component---src-pages-behavioral-analysis-js": () => import("./../src/pages/behavioral-analysis.js" /* webpackChunkName: "component---src-pages-behavioral-analysis-js" */),
  "component---src-pages-best-practice-or-code-smell-js": () => import("./../src/pages/best-practice-or-code-smell.js" /* webpackChunkName: "component---src-pages-best-practice-or-code-smell-js" */),
  "component---src-pages-bookshelf-js": () => import("./../src/pages/bookshelf.js" /* webpackChunkName: "component---src-pages-bookshelf-js" */),
  "component---src-pages-changing-untested-code-js": () => import("./../src/pages/changing-untested-code.js" /* webpackChunkName: "component---src-pages-changing-untested-code-js" */),
  "component---src-pages-check-your-emails-js": () => import("./../src/pages/check-your-emails.js" /* webpackChunkName: "component---src-pages-check-your-emails-js" */),
  "component---src-pages-code-feels-impossible-to-maintain-js": () => import("./../src/pages/code-feels-impossible-to-maintain.js" /* webpackChunkName: "component---src-pages-code-feels-impossible-to-maintain-js" */),
  "component---src-pages-dependency-graphs-js": () => import("./../src/pages/dependency-graphs.js" /* webpackChunkName: "component---src-pages-dependency-graphs-js" */),
  "component---src-pages-first-aid-kit-js": () => import("./../src/pages/first-aid-kit.js" /* webpackChunkName: "component---src-pages-first-aid-kit-js" */),
  "component---src-pages-first-aid-kit-unsubscribe-js": () => import("./../src/pages/first-aid-kit/unsubscribe.js" /* webpackChunkName: "component---src-pages-first-aid-kit-unsubscribe-js" */),
  "component---src-pages-getting-into-large-codebase-js": () => import("./../src/pages/getting-into-large-codebase.js" /* webpackChunkName: "component---src-pages-getting-into-large-codebase-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-making-others−care-about-it-js": () => import("./../src/pages/making-others−care-about-it.js" /* webpackChunkName: "component---src-pages-making-others−care-about-it-js" */),
  "component---src-pages-premiers-soins-js": () => import("./../src/pages/premiers-soins.js" /* webpackChunkName: "component---src-pages-premiers-soins-js" */),
  "component---src-pages-typing-the-edges-js": () => import("./../src/pages/typing-the-edges.js" /* webpackChunkName: "component---src-pages-typing-the-edges-js" */)
}

